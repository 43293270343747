<template>
  <CRow>
    <CCol col="12" xl="10">
      <CCard>
        <CCardHeader>
          <CButton class="float-left" disabled variant="ghost" color="dark">Reports</CButton>
          <CButton class="float-right col-sm-2" variant="outline" @click="createReport"  color="success">Create Report</CButton>
          
        </CCardHeader>
        <CCardBody>         
          <CDataTable
            sorter            
            column-filter
            items-per-page-select
            hover
            striped
            :sorter-value="{ column: 'report_title', asc: 'true' }"   
            :items="reportsTableItems"
            :fields="fields"
            :items-per-page="20"
            :active-page="activePage"
            :pagination="{ doubleArrows: true, align: 'center'}"
            @page-change="pageChange"
            :loading=loading
          > 
              <td slot="report_customer_name" slot-scope="{item}">
                {{ item.report_customer_name }}
              </td>
              <td slot="report_last_url" slot-scope="{item}" v-c-tooltip="{ content: 'Open the Report in a new tab.' }">
                <CButton                     
                  :id="item.report_last_url"
                  :href="item.report_last_url"
                  :disabled="item.report_last_url.length < 1"
                  target="_blank"
                  color="info"
                  style="display: inline-grid;">
                  <span class="material-icons md-18">link</span>
                </CButton>
              </td>
              <td slot="report_edit" slot-scope="{item}">
                  <CButton v-on:click="openReport(item.report_id)" :id="item.report_id" block color="dark" style="display: inline-grid;"><span class="material-icons md-18">edit</span></CButton>
              </td>
              <td slot="report_delete" slot-scope="{item}">
                  <CButton v-on:click="deleteReport(item)" :id="item.report_id" block color="danger"  style="display: inline-grid;"> <span class="material-icons md-18">delete</span></CButton>
              </td>
          </CDataTable>
        </CCardBody>

      </CCard>
      
    </CCol>

    <CModal
      title="Delete Report"
      color="danger"
      :show.sync="deleteModal">
      
      <p>Do you really want to delete the Report <strong> {{ deleteReportItem.report_name }}</strong>?</p>
      <p>This <strong>can't</strong> be undone!</p>
      
      <div slot="footer" class="w-100">
          <CButton v-on:click="deleteReportApi(deleteReportItem.report_id);" color="danger" class="col-sm-2 ml-1 mr-1 float-right" >Delete</CButton>  
          <CButton @click="deleteModal = false"  color="light" class="ml-1 mr-1 col-sm-2 float-right" >Cancle</CButton>  
      </div>
    </CModal>      
  
  </CRow>
</template>

<script>
import ReportService from '@/services/report.services';
import CustomerService from '@/services/customer.services';
import WorkloadService from '@/services/workload.services';

export default {
  data() {
    return {    
      reports: [ ],
      reportsTableItems: [ ],
      workloads: [ ],
      customers: [ ],
      fields: [
        { key: 'report_name', label: 'Name'},
        { key: 'report_customer_name', label: 'Customer' },
        { key: 'report_workload_name', label: 'Workload' },
        { key: 'report_status', label: 'Status', _style:'width:10%' },
        { key: 'report_last_url', label: 'Report', sorter: false,  filter: false, _style:'width:1%' },
        { key: 'report_edit', label: '', sorter: false,  filter: false, _style:'width:1%' },
        { key: 'report_delete', label: '', sorter: false,  filter: false, _style:'width:1%' },
      ],
      activePage: 1,
      loading: true,
      deleteReportItem: {},
      deleteModal: false,
      
    };
  },

  methods: {

    createReport() {
      this.$router.push({path: '/reports/create'})
    },
    async deleteReport(report) {
      this.deleteReportItem = report
      this.deleteModal = true
    },
    async processReportData() {

      var tmp = {}
      for (var key in this.reports) {
        tmp = this.reports[key];
        var customerId = this.reports[key].report_customer_id;
        var customer = this.findCustomer(customerId);

        var workloadId = this.reports[key].report_workload_id;
        var workload = this.findWorkload(workloadId);

        tmp['report_customer_name'] = customer.customer_name
        tmp['report_workload_name'] = workload.WorkloadName

        this.reportsTableItems.push(tmp)
      }
      this.loading = false;
 
    },    
    findCustomer(id) {
      var tmp = {}          
      tmp["customer_id"] = id

      const index = this.customers.findIndex(tmp => tmp.customer_id === id); 

      if (index > -1) {
        return this.customers[index]
      } else {
        return {}
      }             
    },
    findWorkload(id) {
      var tmp = {}          
      tmp["WorkloadId"] = id

      const index = this.workloads.findIndex(tmp => tmp.WorkloadId === id); 

      if (index > -1) {
        return this.workloads[index]
      } else {
        return {}
      }             
    },        
    openReport (report_id) {
      this.$router.push({path: `reports/${report_id}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    async deleteReportApi(id) {
      ReportService.deleteReport(id).then(
        response => { 
          const index = this.reportsTableItems.indexOf(this.deleteReportItem);
            if (index > -1) {
              this.reportsTableItems.splice(index, 1);
            }
            this.deleteModal = false;
        },
        error => {   
          console.log(error)   
        }      
      ) 
    },     
  },
  created() {  },
  async mounted() {
    CustomerService.getCustomers().then(
      response => { 
        this.customers = response;
      },
      error => {   
        console.log(error)   
      }      
    ),
    await WorkloadService.getWorkloads().then(
      response => { 
        this.workloads = response;
      },
      error => {   
        console.log(error)   
      }      
    )
    await ReportService.getReports().then(
      response => { 
        this.reports = response;
      },
      error => {   
        console.log(error)   
      }      
    )
    this.processReportData()
  },

  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },


};

</script>
